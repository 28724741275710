import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useOrgsList() {
  // Use toast
  const toast = useToast()

  const refOrgListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', label: 'Organisation', sortable: true },
    { key: 'max_members', label: 'Seats', sortable: true },
    { key: 'member_count', label: 'Users', sortable: true },
    { key: 'all_analytics_count', label: 'Total views', sortable: true },
    { key: 'last_28_days_analytics_count', label: '1mo views', sortable: true },

    // { key: 'member_enabled_count', label: 'Seats in use', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(100)
  const totalOrgs = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(false)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const requestDone = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refOrgListTable.value ? refOrgListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalOrgs.value,
    }
  })

  const refetchData = () => {
    refOrgListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchOrgs = (ctx, callback) => {
    requestDone.value = false
    store
      .dispatch('app/fetchOrgs', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { results, total } = response.data

        callback(results)
        totalOrgs.value = total
        requestDone.value = true
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching orgs list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveOrgRoleVariant = role => {
    if (role === 'member') return 'primary'
    if (role === 'manager') return 'warning'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveOrgRoleIcon = role => {
    if (role === 'member') return 'OrgIcon'
    if (role === 'manager') return 'SettingsIcon'
    if (role === 'admin') return 'ServerIcon'
    return 'OrgIcon'
  }

  const resolveOrgStatusVariant = status => {
    if (status) return 'success'
    if (!status) return 'secondary'
    return 'secondary'
  }

  const statusOptions = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
  ]

  return {
    fetchOrgs,
    tableColumns,
    perPage,
    currentPage,
    totalOrgs,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refOrgListTable,

    resolveOrgRoleVariant,
    resolveOrgRoleIcon,
    resolveOrgStatusVariant,
    statusOptions,
    refetchData,
    requestDone,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
